@font-face {
  font-family: 'inter-ui--regular';
  src: url("../fonts/inter-ui--regular/inter-ui--regular.eot");
  src: url("../fonts/inter-ui--regular/inter-ui--regular.eot?#iefix") format('embedded-opentype'), url("../fonts/inter-ui--regular/inter-ui--regular.woff") format('woff'), url("../fonts/inter-ui--regular/inter-ui--regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'inter-ui--italic';
  src: url("../fonts/inter-ui--italic/inter-ui--italic.eot");
  src: url("../fonts/inter-ui--italic/inter-ui--italic.eot?#iefix") format('embedded-opentype'), url("../fonts/inter-ui--italic/inter-ui--italic.woff") format('woff'), url("../fonts/inter-ui--italic/inter-ui--italic.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'inter-ui--medium';
  src: url("../fonts/inter-ui--medium/inter-ui--medium.eot");
  src: url("../fonts/inter-ui--medium/inter-ui--medium.eot?#iefix") format('embedded-opentype'), url("../fonts/inter-ui--medium/inter-ui--medium.woff") format('woff'), url("../fonts/inter-ui--medium/inter-ui--medium.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.contain {
  display: block;
  width: 92%;
  max-width: 920px;
  margin-right: auto;
  margin-left: auto;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  word-wrap: break-word;
}
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  background-color: #101010;
}
body * {
  color: #ebebeb;
}
html[data-focus-source="key"] :focus {
  z-index: 90;
  outline-offset: 6px;
  outline: 2px solid $cl--focus;
}
html :focus {
  outline: 0;
}
.main_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.page_content {
  flex: 1;
  width: 100%;
}
.page_content__inner {
  display: flex;
  width: 92%;
  max-width: 920px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  align-items: flex-start;
}
.page_content__inner > *:first-child {
  margin-top: 2.427rem;
}
.page_content__inner > *:last-child {
  margin-bottom: 2.427rem;
}
img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}
* + img {
  margin-top: 1.618rem;
}
svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
input,
button {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --ratio: 1.125;
  --fz--h1: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz--h2: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz--h3: calc(1rem * var(--ratio) * var(--ratio));
  --fz--h4: calc(1rem * var(--ratio));
  --fz--sub: calc(1rem / var(--ratio));
  --fz--supersub: calc((1rem / var(--ratio)) / var(--ratio));
  --sp--h1: calc(var(--fz--h1) * 1.5);
  --sp--h2: calc(var(--fz--h2) * 1.5);
  --sp--h3: calc(var(--fz--h3) * 1.5);
  --sp--h4: calc(var(--fz--h4) * 1.5);
}
:root {
  font-size: 13px;
}
@media screen and (min-width: 480px) {
  :root {
    font-size: calc(0.5vw + 10.6px);
  }
}
@media screen and (min-width: 1680px) {
  :root {
    font-size: 19px;
  }
}
@media only screen and (min-width: 681em) {
  :root {
    --ratio: 1.18;
  }
}
@media only screen and (min-width: 1281em) {
  :root {
    --ratio: 1.24;
  }
}
body,
select,
input,
textarea {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  line-height: 1.618;
  font-size: inherit;
  color: #ebebeb;
}
h1,
h2,
h3,
h4 {
  display: block;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: $cl--med;
}
h1 a,
h2 a,
h3 a,
h4 a,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited {
  color: $cl--anchor;
}
h1 a:active,
h2 a:active,
h3 a:active,
h4 a:active,
h1 a:visited:active,
h2 a:visited:active,
h3 a:visited:active,
h4 a:visited:active,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h1 a:visited:hover,
h2 a:visited:hover,
h3 a:visited:hover,
h4 a:visited:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h1 a:visited:focus,
h2 a:visited:focus,
h3 a:visited:focus,
h4 a:visited:focus {
  color: $cl--anchor--hover;
}
h1 {
  max-width: 30ch;
  font-size: 1.601806640625rem;
  font-size: var(--fz--h1);
}
@media only screen and (min-width: 681em) {
  h1 {
    font-size: 1.93877776rem;
    font-size: var(--fz--h1);
  }
}
@media only screen and (min-width: 1281em) {
  h1 {
    font-size: 2.36421376rem;
    font-size: var(--fz--h1);
  }
}
h2 {
  max-width: 30ch;
  font-size: 1.423828125rem;
  font-size: var(--fz--h2);
}
@media only screen and (min-width: 681em) {
  h2 {
    font-size: 1.643032rem;
    font-size: var(--fz--h2);
  }
}
@media only screen and (min-width: 1281em) {
  h2 {
    font-size: 1.906624rem;
    font-size: var(--fz--h2);
  }
}
* + h2 {
  margin-top: 2.1357421875rem;
  margin-top: var(--sp--h2);
}
@media only screen and (min-width: 681em) {
  * + h2 {
    margin-top: 2.464548rem;
    margin-top: var(--sp--h2);
  }
}
@media only screen and (min-width: 1281em) {
  * + h2 {
    margin-top: 2.859936rem;
    margin-top: var(--sp--h2);
  }
}
h3 {
  max-width: 42ch;
  font-size: var(--fz--h3);
}
* + h3 {
  margin-top: 1.8984375rem;
  margin-top: var(--sp--h3);
}
@media only screen and (min-width: 681em) {
  * + h3 {
    margin-top: 2.0886rem;
    margin-top: var(--sp--h3);
  }
}
@media only screen and (min-width: 1281em) {
  * + h3 {
    margin-top: 2.3064rem;
    margin-top: var(--sp--h3);
  }
}
h4 {
  max-width: 50ch;
  font-size: var(--fz--h4);
}
* + h4 {
  margin-top: 1.6875rem;
  margin-top: var(--sp--h4);
}
@media only screen and (min-width: 681em) {
  * + h4 {
    margin-top: 1.77rem;
    margin-top: var(--sp--h4);
  }
}
@media only screen and (min-width: 1281em) {
  * + h4 {
    margin-top: 1.86rem;
    margin-top: var(--sp--h4);
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: -0.05em;
  color: $cl--med;
}
* + p {
  margin-top: 1.618rem;
}
h1 + p,
h2 + p,
h3 + p {
  margin-top: 0.539333333333333rem;
}
h4 + p {
  margin-top: 0.809rem;
}
.reading_content p,
p.reading_content {
  max-width: 60ch;
}
.reading_content p > a,
p.reading_content > a,
.reading_content p > a:visited,
p.reading_content > a:visited {
  color: $cl--anchor;
}
.reading_content p > a:active,
p.reading_content > a:active,
.reading_content p > a:visited:active,
p.reading_content > a:visited:active,
.reading_content p > a:hover,
p.reading_content > a:hover,
.reading_content p > a:visited:hover,
p.reading_content > a:visited:hover,
.reading_content p > a:focus,
p.reading_content > a:focus,
.reading_content p > a:visited:focus,
p.reading_content > a:visited:focus {
  color: $cl--anchor--hover;
}
a,
a:visited {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  text-decoration: none;
  color: #ce599f;
  font-style: italic;
}
a:hover,
a:visited:hover {
  text-decoration: underline;
}
b,
strong {
  font-weight: normal;
  font-family: $ff--strong;
}
i,
em {
  font-style: normal;
  font-family: $ff--emphasis;
}
::selection {
  background-color: $cl--first;
  text-shadow: 0 0 0.1em $cl--first--dark;
  color: #fff;
}
.reading_content__list {
  display: block;
  width: 60ch;
  max-width: 100%;
  padding-left: 3ch;
}
* + .reading_content__list {
  margin-top: 1.618rem;
}
ol.reading_content__list {
  list-style-type: decimal;
}
ul.reading_content__list {
  list-style-type: disc;
}
.reading_content__list li {
  max-width: 100%;
}
.reading_content__list li + li {
  margin-top: 0.809rem;
}
sup {
  vertical-align: super;
  font-size: 0.888888888888889rem;
  font-size: var(--fz--sub);
}
@media only screen and (min-width: 681em) {
  sup {
    font-size: 0.847457627118644rem;
    font-size: var(--fz--sub);
  }
}
@media only screen and (min-width: 1281em) {
  sup {
    font-size: 0.806451612903226rem;
    font-size: var(--fz--sub);
  }
}
.global_footer {
  width: 100%;
}
.global_footer__inner {
  display: flex;
  width: 92%;
  max-width: 920px;
  margin-right: auto;
  margin-left: auto;
  padding: 1.618rem 0;
}
.global_footer__inner nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.global_footer__inner ul {
  display: flex;
}
.global_footer__inner li {
  padding: 0 0.809rem;
}
.global_footer__inner a {
  color: #696969;
}
.global_header {
  width: 100%;
}
.global_header__inner {
  display: flex;
  width: 92%;
  max-width: 920px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  border-bottom: 1px solid #fff;
}
.global_header__inner nav {
  padding-bottom: 0.809rem;
}
.global_header__inner ul {
  display: flex;
}
.global_header__inner li {
  padding-right: 1.618rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: var(--fz--h4);
}
.global_header__inner li a.active {
  font-weight: 700;
  text-decoration: underline;
}
.page_title {
  padding: 1.618rem 0;
  font-size: 2rem;
  font-family: 'Roboto Mono', monospace;
}
.page_title span {
  display: block;
  color: #8ef592;
}
